import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter } from '@angular/core';
var DtComponent = /** @class */ (function () {
    function DtComponent() {
        this.docChange = new EventEmitter();
    }
    Object.defineProperty(DtComponent.prototype, "text", {
        get: function () { return this.doc && this.doc.contents; },
        enumerable: true,
        configurable: true
    });
    DtComponent.prototype.dtextSet = function () {
        this.doc.contents = this.dt.nativeElement.value;
        this.docChange.emit(tslib_1.__assign({}, this.doc));
    };
    return DtComponent;
}());
export { DtComponent };
